.blur-effect {
    padding: 0;
    margin: 0;
    filter: blur(6px);  
}

.heat-effect-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100px; /* Fixed height */
    z-index: 10; /* High z-index to ensure visibility */
    opacity: 0.5; /* Example: 50% opacity */
}
  

.shows-container {
    position: relative;
    padding-top: 60px; /* Adjust this value so the content starts below the nav */
    min-height: calc(100vh - 60px); /* Subtract the nav height */
  }
  
  .emotion-graph-container {
    position: absolute;
    bottom: 0px; /* Move up by 20px; adjust this value as needed */
    width: 100%;
    height: auto; /* Or you can set a fixed height if you prefer */
  }

  /* Shows.css */
.unique-shows-class {
    /* Specific positioning styles for Shows page */
    text-align: right; /* Example: center align */
    margin-right: 180px;
    margin-top: -30px; /* Adjust the margin as needed */
    /* Add other specific styles as required */
}



.small-cards-container-show {
    position: absolute;
    bottom: 300px; /* Distance from the bottom of the page */
    right: 100px; /* Distance from the left of the page */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */
    gap: 10px; /* Adjust the space between cards */
  }
  
  
  