/* Custom styles for react-tooltip */
.__react_component_tooltip {
    max-width: 200px; /* Example: Set a maximum width */
    background-color: #333; /* Example: Background color */
    color: white; /* Example: Text color */
    /* Add more custom styles as needed */
    margin-top: -10px; 
  }

  .playlists-graphs {
    margin-top: -100px; /* Moves the tooltip up */
    opacity: 0.95; /* Adjust the opacity as needed */
    background-color: #444; /* Custom background color */
    color: white; /* Custom text color */
    border-radius: 8px; /* Rounded corners */
    font-size: 14px; /* Adjust the font size as needed */
    padding: 8px 16px; /* Padding inside the tooltip */
    width: max-content; /* Adjust width to fit content */
  }
  
  

  /* GlobeTooltip.css */
.warming-centered-title {
  text-align: center;
}

.energy-centered-title {
  text-align: center;
}

.videos-centered-title {
  text-align: center;
}

.fotos-centered-title {
  text-align: center;
}

.global-centered-title {
  text-align: center;
}

.userplaylists-centered-title {
  text-align: center;
}

.superplaylists-centered-title {
  text-align: center;
}

.footnote {
  font-size: 10px; /* Adjust the size as needed */
  text-align: center;
  color: #666; /* Adjust the color as needed */
  margin-top: 10px;
}
