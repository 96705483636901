.graph-container {
    position: fixed; /* or absolute, depending on the overall layout */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px; /* Adjust height as needed */
    background-color: rgba(101, 68, 68, 0.4); /* 50% opacity for the background color only */
    z-index: 10; /* Ensure it's above other content */
    display: flex;
    justify-content: space-around; /* Distribute space between graphs */
    padding: 1px 0; /* Padding on top and bottom inside the container */
    box-sizing: border-box;
}

.graph-item {
    margin-top: auto; /* Push the graph upwards */
    padding: 5px; /* Space between graphs */
    box-sizing: border-box;
}
