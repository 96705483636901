/* styles/Lambe.css */

.lambe-image-container {
    position: fixed;
    bottom: 18px; /* Increase to move the image up */
    right: 0;
    max-width: 100px; /* Adjusted to maintain aspect ratio */
    max-height: 175px; /* Adjusted to maintain aspect ratio */
    overflow: hidden;
}

.lambe-image {
    width: 100%;
    height: auto;
    opacity: 0.7;
    /* Additional styling as needed */
}


.shows-lambe-image-container {
    bottom: 205px; /* Adjust this value as needed */
    /* Other styles remain the same */
}

.fotos-lambe-image-container {
    bottom: 148px; /* Adjust this value as needed */
    /* Other styles remain the same */
}