
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.icon-modal-content {
  position: fixed;
  background: white;
  width: 70%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.download-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #4CAF50; /* Green background */
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.5vw 1vw; /* Example of using viewport units for padding */

/* Responsive design for the download button */
@media (max-width: 600px) {
  .download-button {
    padding: 12px 20px; /* Adjusted for better visibility on smaller screens */
  }
}
  /* Add a hover effect */
  &:hover {
    background-color: #45a049;
  }
}

.carousel-slide {
  position: relative;
}

.carousel-media {
  width: 100%; /* Ensures media scales to fit the width of its container */
  height: auto; /* Maintains aspect ratio but can be adjusted */
  object-fit: cover; /* Cover ensures the aspect ratio is maintained while filling the area */
  max-height: 1000px; /* Adjust based on your preference to prevent thumbnails from being too tall */
  margin: 0 auto; /* Center the media */
}

/* Other styles remain unchanged */

.carousel-video-thumbnail-container {
  position: relative;
  width: 100%; /* Full width to match the carousel slide */
  padding-top: 56.25%; /* Aspect ratio for 16:9 videos, adjust as needed */
  background: #000; /* Optional: background color */
}

.carousel-video-thumbnail {
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Use 'contain' to ensure the full thumbnail is visible without cropping */
}
