/* Standard Carousel Image */
.carousel-image {
  max-width: 100%;
  height: auto;
  object-fit: cover; /* This will display the full image within its frame */
}

/* Larger Image for 'Cifras' Gallery */
.carousel-image-large {
  max-width: 100%; /* Use the full width of the container */
  max-height: 600px; /* Larger height for 'Cifras' */
  height: auto;
  object-fit: contain; /* Keeps the entire image visible, maintaining aspect ratio */
}

.carousel-container {
  width: 100%; /* Ensure container uses the full width of the modal */
  margin: auto;
}

.modal-carousel-content {
  width: 60vw; /* Standard modal width */
  max-height: 80vh; /* Enough height to avoid unnecessary scroll */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto; /* Enable vertical scrolling if needed */
  background: transparent; /* Set to transparent or your desired background */
}

.modal-carousel-content-large {
  width: 80vw; /* Increased width for 'Cifras' gallery */
  max-height: 80vh;
}

/* Style for video thumbnails */
.carousel-video-thumbnail {
  width: 100%; /* Full width of the container */
  height: auto; /* Height adjusts automatically */
  object-fit: cover; /* Cover might crop the image, consider using contain for no crop */
  display: block; /* Removes bottom space/margin */
  max-height: 400px; /* Set a max-height to control the size */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .carousel-media, .carousel-video-thumbnail {
    max-height: 400px; /* Adjust height for smaller screens */
  }
}