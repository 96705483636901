#social-icons {
    display: flex;
    flex-direction: column; /* Stack icons vertically */
    align-items: center; /* Center icons horizontally in the container */
    justify-content: start; /* Align icons to the start of the container */
    position: fixed; /* Position icons relative to the viewport */
    top: 25%; /* Center container vertically */
    left: 0; /* Align container to the left edge of the viewport */
    transform: translateY(-50%); /* Center container vertically with respect to its height */
    margin-left: 10px; /* Adjust this value to control distance from the left margin */
    z-index: 1000; /* Ensure icons are above other content */
}

#social-icons a {
    color: white; /* Icon color */
    margin: 5px 0; /* Margin between icons, adjust as needed */
    display: flex; /* Use flex to center the content of the link */
    align-items: center; /* Center icon vertically */
    justify-content: center; /* Center icon horizontally */
}

.fab {
    font-size: 24px; /* Adjust icon size as needed */
}
