/* styles/Musica.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.redirectToLogin-container {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(90, 137, 167);
    opacity: 0.9;
    font-family: 'Montserrat', sans-serif; /* Updated to Montserrat */
    color: white;
    font-size: var(--font-size, 16px); /* Default font size is 16px, but can be changed using --font-size */
}

.redirectToLogin-container p {
    font-weight: bold; /* This makes the text bold */
    margin: 0;
}

.redirectToLogin-container button {
    margin-left: 1rem;
    padding: 10px 20px;
    background: linear-gradient(45deg, #1DB954, #1ed760); /* Spotify green gradient */
    border: none;
    border-radius: 5px;
    color: white;
    font-family: 'Montserrat', sans-serif; /* Updated to Montserrat */
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.spotify-player-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;  
  }
  

.redirectToLogin-container button:hover {
    background: linear-gradient(45deg, #1ed760, #1DB954); /* Switch gradient colors on hover */
    transform: scale(1.05);
}

.small-cards-container-musica {
    position: absolute;
    bottom: 23px; /* Distance from the bottom of the page */
    right: 100px; /* Distance from the left of the page */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */
    gap: 10px; /* Adjust the space between cards */
  }