@font-face {
  font-family: 'RansomRegular';
  src: url('/public/fonts/RansomRegular.otf') format('opentype');
}

.custom-h1 {
  font-family: 'RansomRegular', sans-serif;
  color: white;
}


/* @font-face {
  font-family: 'Spac3';
  src: url('/public/fonts/Spac3.ttf') format('truetype');
} */

body, html {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}


#p5-container {
  margin: 0;
  padding: 0;
  position: relative;
  height: 10vh; /* This sets the height of the p5.js canvas container */
  width: 100%;
}

#main-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  }

#main-nav {
  position: absolute;  /* Absolute positioning */
  top: 0;  /* Stick to the top of the main-container */
  left: 50%;  /* Centering */
  transform: translateX(-50%);  /* Centering */
  z-index: 1;  /* Ensure it's above other elements */
}

#main-nav ul {
list-style: none;
margin: 0;
padding: 0;
display: flex;
}

#main-nav ul li {
margin: 0 28px;
}

#main-nav ul li a {
  text-decoration: none;
  color: white;
  font-family: 'RansomRegular', sans-serif;
}

#social-icons a {
  margin-left: 10px;  /* Adjust this value as needed */
}

#social-icons a {
  text-decoration: none !important; /* Forcefully removes the underline */
  color: white;  /* Set the color to white */
  font-size: 24px;  /* Set the size, adjust as needed */
  display: block;  /* Each icon will take the full width, appearing vertically */
  margin-bottom: 10px;  /* Add some space between the icons */
}

.fab {
    font-size: 20px;
  }
  
.page-container {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
  }
  
/* Form container */
.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Form labels */
.form-container label {
  display: inline-block;
  width: 100px;
  font-weight: bold;
}

/* Form inputs */
.form-container input[type="text"],
.form-container input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit Button */
.form-container input[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container input[type="submit"]:hover {
  background-color: #0056b3;
}

/* New additions for login page */

/* Background image styling for login page */
body.login-page {
  /*background-image: url('/static/images/your-image.jpeg');*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;  /* This ensures the background spans the entire page */
  height: 100vh;  /* This will make sure to take the full height of the viewport */
}


/* Login form styling */
.form-container {
  position: absolute;  /* Absolute positioning */
  top: 50%;  /* Center vertically */
  left: 50%;  /* Center horizontally */
  transform: translate(-50%, -50%);  /* Necessary for exact centering */
  z-index: 2;  /* Ensure it's above other elements */
  background-color: rgba(255, 255, 255, 0.8);  /* Semi-transparent white */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container label,
.form-container input {
  display: block;
  margin-bottom: 10px;
}

.form-container input[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container input[type="submit"]:hover {
  background-color: #0056b3;
}



