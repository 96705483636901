/* StreamingServicesModal.css */

@font-face {
    font-family: 'RansomRegular';
    src: url('/public/fonts/RansomRegular.otf') format('opentype');
  }

.streaming-services-modal {
    background: #fff;
    padding: 0; /* Remove padding to have the modal fit content */
    border-radius: 10px; /* Rounded corners for the modal */
    text-align: center; /* Centering text */
    color: #000; /* Text color for the title and subtitle */
    width: 100%; /* Full width */
    max-width: 400px; /* Maximum width of modal */
    margin: auto; /* Center modal horizontally */
  }
  
.modal-overlay {
    background: rgba(0, 0, 0, 0.75); /* Dark overlay */
  }
  
.modal-header {
    background: #000; /* Black background for the header */
    color: #fff; /* White text */
    padding: 15px 20px; /* Padding inside the header */
    position: relative; /* For the pointer */
    border-top-left-radius: 10px; /* Rounded corner top left */
    border-top-right-radius: 10px; /* Rounded corner top right */
    text-align: center; /* Center the title text */
    display: flex; /* Use flexbox to center content */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
  }
  
.modal-title {
  font-family: 'RansomRegular', sans-serif;
  color: white;
  font-size: 24px; /* Adjust the size as needed */
  text-align: center; /* Center the title text */
  margin: 0; /* Remove default margin */
}
  
.modal-subtitle {
    font-size: 18px; /* Smaller font size for the subtitle */
  }
  
.modal-pointer {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #000;
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
  }
  
.services-list {
    display: flex;
    flex-direction: column;
    margin: 0; /* Remove margin */
}
  
  .service-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f2f2; /* Light grey background for each service */
    padding: 10px;
    border-radius: 5px; /* Rounded corners for the service items */
    margin-bottom: 10px; /* Space between service items */
  }

.modal-pointer {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #f2f2f2; /* Match the service item background */
  }

/* Style for the first service to connect with the pointer */
.services-list:first-child {
    position: relative;
    top: -20px;
  }
  
  .service-item img {
    height: 40px; /* Fixed height for logos */
  }
  
  .service-item button {
    background: #808080; /* Neutral color for the button */
    color: #fff; /* White text on the button */
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  