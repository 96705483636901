/* SmallCard.css */
.small-card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    margin: 8px;
    border: 1px solid #000;
    background-color: rgba(255, 255, 255, 0.5);
    font-family: 'Georgia', serif;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: bold;
    width: 120px; /* Adjust the width as necessary */
    cursor: pointer;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .small-card:hover {
    background-color: rgba(255, 255, 255, 0.3); /* Slightly more opaque on hover */
  }
  