
  
.discrete-home-link a {
    position: fixed; /* Keeps the icon fixed at the top of the page */
    top: 20px; /* adjust as needed */
    left: 250px; /* adjust as needed */
    color:white;
    font-size: 30px; /* Larger icon size */

  }

  .discrete-home-link a:hover {
    color: #101011; /* Color changes on hover */
  }

  .discrete-home-link a {
    transition: transform 0.3s ease;
  }
  
  .discrete-home-link a:hover {
    transform: rotate(360deg); /* Rotates the icon */
  }
  
  .small-cards-container-home {
    position: absolute;
    bottom: 23px; /* Distance from the bottom of the page */
    right: 100px; /* Distance from the left of the page */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */
    gap: 10px; /* Adjust the space between cards */
  }