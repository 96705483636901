
.shows-container {
    /* Your main container should be relative if you want to use absolute positioning for children */
    position: relative;
  }
  
  .tour-card-wrapper {
    position: absolute;
    right: 150px; /* Adjust as needed for the right offset */
    top: 150px; /* Adjust as needed for the top offset */
  }
  
  .tour-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid #000; /* Solid black border */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 8px;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
    font-family: 'Georgia', serif; /* A more appropriate guess for a serif font */
    font-size: 0.9rem; /* Smaller font size, adjust as needed */
    width: 200px; /* Set a fixed width to make the cards small */
    text-align: center; /* Center the text */
    font-style: italic; /* Make all text italic */
  }
  
  .date-range, .event-name, .location {
    margin-bottom: 8px;
    font-weight: bold; /* Makes the text bold */
  }
  
  .get-tickets-btn {
    padding: 8px 16px;
    border: 1px solid #000; /* Solid black border */
    background-color: transparent; /* Transparent background */
    color: black; /* Black text */
    cursor: pointer;
    font-weight: bold; /* Makes the text bold */
    margin-top: 16px; /* Add space above the button */
    font-style: italic; /* Make the button text italic */
  }
  
  
  .get-tickets-btn:hover {
    background-color: black; /* Black background on hover */
    color: white; /* White text on hover */
  }
  
  