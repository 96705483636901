.fotos-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will push the graph to the bottom */
    padding-top: 60px; /* Adjust this value so the content starts below the nav */
    min-height: calc(100vh - 60px); /* Subtract the nav height */
  }
  
  .popularity-graph-container {
    width: 100%;
    height: 300px; /* Set a specific height for the graph container */
  }
  

  .small-cards-container {
    position: absolute;
    bottom: 50px; /* Distance from the bottom of the page */
    right: 100px; /* Distance from the left of the page */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */
    gap: 10px; /* Adjust the space between cards */
  }

  .gallery-cards-container {
    display: flex;
    justify-content: center; /* Centers items horizontally */
    align-items: flex-start; /* Aligns items to the start vertically */
    gap: 10px; /* Adjust the space between cards */
    margin-top: -200px; /* Adjust this value to move the container a bit higher */
    width: 100%; /* Ensures the container takes full width */
  }
  
  
  .spinner-container {
    position: fixed; /* or 'absolute' if you want it relative to a specific container */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure it's above other content */
  }
  