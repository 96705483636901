/* Energy.css */
.energy-strip {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20px; /* Adjust the height as needed */
    background-color: #f8f8f8; /* Optional: a fallback or background color */
}

.energy-strip svg {
    display: block;
    width: 100%;
    height: 100%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
    padding-top: 0; /* Reduce or remove padding at the top of the modal */
    background-color: #171616; /* Change background color to black */
    color: #fff; /* Change text color to white for contrast */
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    max-width: 90%; /* Increased from 600px to 90% of the viewport width */
    width: 90%; /* Responsive width */
    max-height: 80vh; /* Adjust height as needed */
    overflow-y: auto; /* Enable scroll if content is too long */
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header-text {
    text-align: center;
    margin-top: 0; /* Reduce the top margin */
    margin-bottom: 10px; /* Reduce the bottom margin if needed */
    padding: 1px; /* Reduce padding if desired */
    color: #fff; /* Adjust text color as needed */
    /* Add other styles as needed */
}

.modal-title {
    font-size: 1.5em;
    font-weight: bold;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
}
