.world-tour-graph-container {
    display: flex;
    justify-content: flex-start; /* Align to the start horizontally */
    align-items: flex-start;     /* Align to the start vertically */
    height: 100%;
    /* Add padding to prevent content from sticking to the edges */
    padding: 10px;
}

.move-right {
    margin-left: 30px; /* Adjust this value as needed to move to the right */
}

.move-top {
    align-self: flex-start; /* Aligns the globe to the top of the container */
    margin-top: -90px; /* Adjust this value as needed for top spacing */
}