/* Styles for the wrapper around video and arrows */
.video-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container {
    width: 100%; /* ensure it takes the full width */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Adjust as needed to center the video in the viewport */
    margin-top: 100px; /* Adjust this value as needed to move videos down */
    gap: 20px;
    margin: 20px 0;
}

/* Styles for the individual videos */
.video {
    cursor: pointer;
}

.swiper-button-prev, .swiper-button-next {
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;  
    top: 190px;  
    z-index: 1;  
    color: rgb(111, 30, 30);
}

.swiper-button-prev {
    left: 570px;  
}

.swiper-button-next {
    right: 570px;  
}



.small-cards-container-videos {
    position: absolute;
    bottom: 140px; /* Distance from the bottom of the page */
    right: 100px; /* Distance from the left of the page */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */
    gap: 10px; /* Adjust the space between cards */
  }