.fanbase-background {
  position: fixed; /* Use fixed to cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Behind all other content */
}


.fanbase-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../../public/static/images/background/fanbase-bg1.jpeg') center/cover no-repeat;
  backdrop-filter: blur(8px); /* This blurs the background image only */
  z-index: -2;
}


/* Optional: Add an overlay to un-blur the central content */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(0px); /* Remove blur */
  pointer-events: none; /* Allow clicks to pass through to elements below */
}


/* Ensure content within .parent-container is not blurred by the overlay */
.parent-container {
  position: relative;
  pointer-events: auto; /* Allow clicks on the content */
}


.background-container {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}


.form-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 380px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.4);
  background: rgba(255, 255, 255, 0.2);
}

.icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 110px;
  z-index: 2;
  position: relative;
  margin-top: -100px; /* Adjust this value as needed to move up */
}

.fa-recycle { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 28px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.fa-guitar { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 38px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.fa-leaf { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 30px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.fa-cow { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 30px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.fa-paint-roller { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 28px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.fa-pencil { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 28px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.fa-user-astronaut { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 35px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.fa-film { /* Use the correct class name for the icon */
  color: #fff;
  font-size: 30px; /* Size of the icon */
  cursor: pointer;
  z-index: 2; /* Ensure it's clickable */
}

.greeting-message {
  font-size: 12px; /* Larger font size */
  color: #fff; /* A darker color for better readability */
  font-weight: bold; /* Bold font */
  padding: 10px; /* Some padding around the text */
  text-align: center; /* Center align text */
  border: 2px solid #fff; /* Solid border for the outline */
  margin-top: 20px; /* Space above the greeting */
  margin-left: 20px; /* Adjust as needed */
  display: inline-block; /* Aligns the border tightly around the text */
}

.label-text {
  font-weight: bold;
  margin-bottom: 8px;
}

.input-field {
  padding: 10px;
  width: 80%; /* Adjust width */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.buttons-container {
  display: flex;
  justify-content: space-between; /* Adjust the spacing between buttons */
  width: 100%; /* Ensure the container takes full width */
}

.submit-button {
  padding: 10px 20px;
  /* width: 50%; Remove or adjust this as needed */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(135deg, #007BFF, #00C6FF);
  color: #fff;
  font-weight: bold;
  flex-grow: 1; /* Allows buttons to grow and fill the container */
}

.submit-button + .submit-button { /* Adds margin between buttons */
  margin-left: 10px;
}


.spotify-button {
  padding: 10px 20px;
  width: 50%;  /* Adjusted width */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(135deg, #1db954, #1ed760);  /* Existing gradient for Spotify */
  color: #fff;  /* Text color */
  font-weight: bold;
  margin-bottom: 20px;  /* Existing margin */
}


.title-animation-fanzone {
  /* Specific positioning styles for Shows page */
  /* text-align: right; */
  margin-left: 20px;
  /* margin-top: 1px;  */
  /* Add other specific styles as required */
}


.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  margin-left: 90px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



